const DataTable = ({ data }) => {
    return (
        <table className="min-w-full table-auto">
            <thead>
                <tr className="bg-gray-800 text-white">
                    <th className="px-4 py-2">Category</th>
                    <th className="px-4 py-2">Amount</th>
                    <th className="px-4 py-2">Unit</th>
                </tr>
            </thead>
            <tbody>
                {data.map(item => (
                    <tr className="bg-gray-200" key={item.category}>
                        <td className="border px-4 py-2">{item.category}</td>
                        <td className="border px-4 py-2">{item.amount}</td>
                        <td className="border px-4 py-2">{item.unit}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};

export default DataTable;