import './logo.css';
import anime from 'animejs';
import {useEffect, useRef} from 'react'

const Logo = ({ className, animate, loop = false }) => {
    const animationRef = useRef(null);
    const direction = loop ? 'alternate' : 'normal'
    const loopClass = loop ? 'loop' : 'onetime'
    const animateClass = animate ? 'animate' : ''

    useEffect(() => {
        if(animate) {
            animationRef.current = anime({
                targets: document.querySelector('#circle-path'),
                d: [
                    {value: `M 151.01,274.56
                            C 135.01,274.37 117.82,269.51 103.58,262.39
                                91.95,256.58 81.76,249.97 72.40,240.81
                                39.32,208.40 28.52,156.65 45.00,113.57
                                58.43,78.49 87.40,51.92 123.55,41.72
                                146.18,36.09 171.91,37.00 184.50,40.88
                                194.82,43.18 225.09,52.73 243.33,76.00
                                250.36,84.36 239.64,94.18 230.73,83.45
                                218.18,72.36 209.01,65.34 195.88,60.25
                                185.32,55.89 169.93,52.47 158.50,52.68
                                135.43,52.68 88.46,59.20 63.61,108.58
                                36.69,162.08 59.38,227.27 114.82,251.20
                                125.92,255.99 140.14,259.43 152.26,259.58
                                170.18,259.09 189.30,257.12 208.00,245.82
                                217.45,240.55 229.53,230.03 234.07,224.64
                                268.75,184.75 268.50,123.25 229.50,82.25
                                221.25,73.50 234.50,64.50 241.75,74.25
                                291.50,127.00 280.00,198.50 242.00,238.37
                                237.97,242.66 227.45,252.91 216.36,259.45
                                196.00,271.45 170.73,274.55 151.01,274.56 Z`},
                    {value: `M 151.01,274.56
                            C 135.01,274.37 117.82,269.51 103.58,262.39
                                91.95,256.58 81.76,249.97 72.40,240.81
                                39.32,208.40 28.52,156.65 45.00,113.57
                                58.43,78.49 87.40,51.92 123.55,41.72
                                146.18,36.09 171.91,37.00 184.50,40.88
                                194.82,43.18 198.27,45.09 206.64,48.73
                                216.18,53.36 209.09,66.55 200.38,62.12
                                197.46,60.96 193.25,59.00 186.88,57.00
                                180.12,55.12 169.93,52.47 158.50,52.68
                                135.43,52.68 88.46,59.20 63.61,108.58
                                36.69,162.08 59.38,227.27 114.82,251.20
                                125.92,255.99 140.14,259.43 152.26,259.58
                                170.18,259.09 189.30,257.12 208.00,245.82
                                217.45,240.55 229.53,230.03 234.07,224.64
                                258.88,193.50 259.75,170.25 259.56,148.19
                                258.94,138.00 272.44,137.06 274.38,147.62
                                275.12,178.12 271.88,203.38 242.00,238.37
                                237.97,242.66 227.45,252.91 216.36,259.45
                                196.00,271.45 170.73,274.55 151.01,274.56 Z`}
                ],
                easing: 'easeInOutQuad',
                duration: 1000,
                delay: 1750,
                endDelay: 1250,
                direction: direction,
                loop: loop
            });
        }
    }, [animate, direction, loop]);

    return (
        <div className={`${className} ${animate ? 'animate' : ''}`}>
            <svg
                viewBox="0 0 312 312"
                version="1.1"
                id="svg1">
                <defs
                    id="defs1">
                    <linearGradient
                        id="linearGradient1">
                    <stop
                        offset="0"
                        id="stop1" />
                    <stop
                        offset="1"
                        id="stop2" />
                    </linearGradient>
                    <linearGradient
                        href="#linearGradient1"
                        id="linearGradient2"
                        x1="1.8696383"
                        y1="1.8396662"
                        x2="311.53259"
                        y2="311.73221"
                        gradientUnits="userSpaceOnUse" />
                    <clipPath id="circle">
                        <path
                        id="circle-path"
                        fill="none"
                        d="M 151.01,274.56 C 135.01,274.37 117.82,269.51 103.58,262.39 91.95,256.58 81.76,249.97 72.40,240.81 39.32,208.40 28.52,156.65 45.00,113.57 58.43,78.49 87.40,51.92 123.55,41.72 146.18,36.09 171.91,37.00 184.50,40.88 194.82,43.18 225.09,52.73 243.33,76.00 250.36,84.36 239.64,94.18 230.73,83.45 218.18,72.36 209.01,65.34 195.88,60.25 185.32,55.89 169.93,52.47 158.50,52.68 135.43,52.68 88.46,59.20 63.61,108.58 36.69,162.08 59.38,227.27 114.82,251.20 125.92,255.99 140.14,259.43 152.26,259.58 170.18,259.09 189.30,257.12 208.00,245.82 217.45,240.55 229.53,230.03 234.07,224.64 268.75,184.75 268.50,123.25 229.50,82.25 221.25,73.50 234.50,64.50 241.75,74.25 291.50,127.00 280.00,198.50 242.00,238.37 237.97,242.66 227.45,252.91 216.36,259.45 196.00,271.45 170.73,274.55 151.01,274.56 Z"/>
                    </clipPath>
                    <clipPath id="circle-2">
                        <path
                        id="circle-2-path"
                        fill="none"
                        d="m 151.01,274.56 c -16,-0.19 -33.19,-5.05 -47.43,-12.17 C 91.95,256.58 81.76,249.97 72.4,240.81 39.32,208.4 28.52,156.65 45,113.57 58.43,78.49 87.4,51.92 123.55,41.72 c 11.9,-2.7 21.19,-4.03 34.95,-4.02 15.66,-0.24 33.37,3.76 47.42,10.67 9.87,3.48 4.54,18.57 -5.98,13.67 -1.73,-0.81 -3.48,-1.59 -5.25,-2.31 -10.56,-4.36 -24.76,-7.26 -36.19,-7.05 -23.07,0 -70.04,6.52 -94.89,55.9 -26.92,53.5 -4.23,118.69 51.21,142.62 11.1,4.79 25.32,8.23 37.44,8.38 C 169,259.5 188,257 207.27,246.36 c 10,-5.81 22.26,-16.33 26.8,-21.72 18.3,-21.69 26.9,-48.87 25.38,-75.44 -0.8,-12.86 15.11,-11.45 14.94,-0.15 1.97,31.84 -8.73,64.17 -32.39,89.32 -4.03,4.29 -15.73,15.08 -23.09,19.45 -17.53,12.06 -44.29,16.43 -67.9,16.74 z"/>
                    </clipPath>
                    <clipPath id="leaf1">
                        <path
                        id="leaf1-path"
                        fill="none"
                        d="m 208.85,240.43 c -1.84,-1.46 -2.56,-2.44 -5.28,-5.18 -16.52,-16.08 -26.83,-40.25 -30.1,-55.06 -3.27,-14.83 -1.13,-25.62 -0.28,-30.58 0.86,-4.99 3.88,-14.68 9.33,-22.63 5.45,-7.95 18.72,-19.73 27.92,-24.49 9.21,-4.76 13.5,6.71 7.02,10.69 -4.65,3.06 -13.7,8.67 -17.22,12.07 -3.51,3.41 -6.58,6.81 -10.21,13.5 -3.63,6.7 -5.44,13.56 -6.14,15.35 -0.71,1.79 -0.22,5.21 4.12,5.16 3.37,-0.05 7.44,0.16 10.76,-0.54 14.64,-2.94 23.26,-9.52 29.73,-14.75 6.46,-5.22 12.48,-14.63 16.91,-24.16 4.41,-9.54 7.62,-25.9 8.94,-33 1.34,-7.09 3.21,-21.53 3.29,-25.42 0.07,-3.91 -2.9,-3.83 -5.07,-2.35 -2.2,1.49 -3.98,2.59 -7.49,4.3 -3.52,1.71 -5.88,2.62 -11.14,4.05 -7.94,2.05 -10.66,3.18 -21.56,6.58 -10.89,3.41 -24.04,10.89 -32.19,19.01 -12.26,12.03 -19.09,29.1 -21.69,50.39 -8.29,78.32 57.52,120.52 67.7,126.27 10.06,6.17 15.91,-3.27 10.3,-8.57 -3.75,-3.36 -4.36,-3.63 -7.88,-6.24 -3.52,-2.61 -18.96,-13.97 -19.77,-14.4 z"/>
                    </clipPath>
                    <clipPath id="leaf2">
                        <path
                        id="leaf2-path"
                        fill="none"
                        d="m 135.72,178.23 c -6.58,-4.31 -22.38,-18.71 -27.14,-23.93 -4.77,-5.23 -4.2,-3.98 -6.02,-6.47 -5.33,-6.01 0.91,-15.21 8.29,-9.53 8.16,8.51 23.25,24.85 30.74,28.25 3.07,1.71 6.41,0.64 6.02,-2.4 -0.68,-10.31 -4.43,-24.37 -11.69,-31.41 -4.99,-5.44 -16.91,-14.29 -30.4,-16.79 -11.59,-1.73 -18.72,-0.22 -23.15,-0.22 -5.72,0.13 -8.55,0.49 -8.18,8.09 0.45,6.82 1.4,16.01 3.19,26.5 1.9,9.57 8.28,18.38 12.81,22.7 5.91,5.78 17.14,13.04 24.85,14.74 7.71,1.71 21.44,1.49 26.21,-0.11 4.77,-1.59 2.38,-4.12 1.1,-5.13 -1.64,-0.97 -0.05,0.02 -6.63,-4.29 z"/>
                    </clipPath>
                </defs>
                <path
                    id="border"
                    fill="none"
                    d="m 54.91,0.17 c 0,0 66.15,0 66.15,0 0,0 126.04,0 126.04,0 37.81,-0.11 64.84,26.92 64.9,64.73 0,0 0,182.2 0,182.2 -0.06,37.81 -27.09,64.84 -64.9,64.9 0,0 -182.2,0 -182.2,0 C 27.09,311.94 0.06,284.91 0,247.1 0,247.1 0,64.9 0,64.9 0.05,30.81 21.29,6.07 54.91,0.17 Z"/>
                <path
                    className={`${loopClass} ${animateClass}`}
                    id="circle-fill"
                    fill="none"
                    d="m 262.25,191.5 c -24,65 -75.5,75 -111.25,76.5 -14.88,-0.18 -31.76,-5.15 -45,-11.75 C 55.2,230.91 30.99,171.44 50.44,118 62.02,86.17 86.87,61.14 119,50.08 c 9.78,-3.37 16.03,-4.15 26,-5.87 0,0 14,0 14,0 13.58,-0.19 30.73,3.78 43,9.57 14.25,6.73 26.17,14.38 36.83,26.22 43.84,51 38.17,137 -27.5,173.33"
                    clipPath="url(#circle)"/>
                <path
                    className={`${loopClass} ${animateClass}`}
                    id="leaf1-fill"
                    fill="none"
                    d="m 265,288 c 0,0 -60.5,-38.5 -82.5,-62 -18,-20.5 -24.5,-33.5 -27.5,-58.5 -3,-25 6,-46 14,-59.5 8,-14.5 28,-25 40.5,-28.5 C 222,76 227,74.5 236,73 c 9.5,-2 12,6 12,12.5 0,6.5 -9.5,23.5 -16.5,31.5 -7,8 -26,25 -36.5,32"
                    clipPath="url(#leaf1)"/>
                <path
                    className={`${loopClass} ${animateClass}`}
                    id="leaf2-fill"
                    fill="none"
                    d="m 170.5,193.5 c 0,0 -35,-6 -51.5,-13.5 -16.5,-7.5 -24,-16.5 -28.5,-28 -4.5,-11.5 -6,-20 -2,-25 4,-5 21.5,-1 27.5,0.5 6,1.5 23,22 25.5,30"
                    clipPath="url(#leaf2)"/>
            </svg>
        </div>
    )
}

export default Logo;