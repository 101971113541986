import React, { useState, useEffect, useRef } from 'react';
import { imagesArray, iconsArray, labelsArray } from './LC_images.js';
import Cutout from '../cutout.js'
import './section1.css';

const Section1 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const iconsContainerRef = useRef(null); // Ref for the icons container
  const iconRef = useRef(null); // Ref for one of the icons
  const timeoutRef = useRef(null); // Ref for one of the icons

  // Function to recalculate sizes and other values
  const recalculateValues = () => {
    if (iconsContainerRef.current && iconRef.current) {

      // Calculate the width of all icons combined
      const containerWidth = iconsContainerRef.current.offsetWidth; // Get the container width

      //get computed width of icon
      const iconStyle = window.getComputedStyle(iconRef.current);
      const marginLeft = parseInt(iconStyle.marginLeft, 10);
      const marginRight = parseInt(iconStyle.marginRight, 10);
      const iconWidth = iconRef.current.offsetWidth + marginLeft + marginRight;
    
      // Set a CSS variable on the container
      document.documentElement.style.setProperty('--containerWidth', `${containerWidth}px`);
      document.documentElement.style.setProperty('--iconWidth', `${iconWidth}px`);

      // console.log(`containerWidth = ${containerWidth}px`)
      // console.log(`iconWidth = ${iconWidth}px`);
    }
  };

  useEffect(() => {
    // Change the first image after 3 seconds to synchronize with the fade-in
    const firstTimeoutId = setTimeout(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesArray.length);

      // After the first image change, set an interval for subsequent changes every 4 seconds
      const intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % imagesArray.length);
        recalculateValues();
      }, 4000); // Continue changing images every 4 seconds

      // Save the intervalId for cleanup
      timeoutRef.current = intervalId;
    }, 3000); // 3 seconds for the first image

    // Set up load event listener for the first icon
    const firstIcon = iconRef.current;
    const handleImageLoad = () => {
      recalculateValues();
    };

    if (firstIcon) {
      firstIcon.addEventListener('load', handleImageLoad);

      // If the image is already loaded by the time we attach the event listener,
      // we should recalculate values immediately
      if (firstIcon.complete) {
        recalculateValues();
      }
    }

    // Cleanup function
    return () => {
      // Clear the interval on component unmount
      clearTimeout(firstTimeoutId);

      // Clear the interval if it's been set
      if (timeoutRef.current) {
        clearInterval(timeoutRef.current);
      }

      // Remove the load event listener
      if (firstIcon) {
        firstIcon.removeEventListener('load', handleImageLoad);
      }
    };
  }, []);

  const renderIcons = () => (
    <div className="flex whitespace-nowrap">
      {iconsArray.map((icon, index) => (
        <img
        key={`icon-${index}`}
        src={icon}
        alt={`Lifecycle step ${index}`}
        className="max-w-[100px] mx-4 md:mx-12"
        ref={index === 0 ? iconRef : null} // Attach the ref to the first icon
        />
      ))}
    </div>
  );

  return (
    <div>
      <div className="relative bg-light-green text-dark-green overflow-hidden md:flex md:items-center md:justify-center py-10">
        <div className="w-full flex flex-col md:flex-row items-center h-[calc(250px+30vw)] sm:h-[70vw] md:h-[25vw]">
          <div className="md:w-1/2 px-12 pb-4 lg:px-36 md:pl-24">
            <h1 className="text-2xl lg:text-3xl xl:text-4xl text-center md:text-left">
              Supply chain collaboration through effective impact communication
            </h1> 
          </div>
          <div className="w-full h-full md:w-1/2 px-8 md:pr-10">
            <div className="relative w-full h-full overflow-hidden">
              {imagesArray.map((src, index) => (
                <img
                  key={src}
                  src={src}
                  alt={`Lifecycle ${index}`}
                  className={`absolute w-full h-auto opacity-0 top-0 left-0 rounded-lg ${index === currentIndex ? 'active' : ''}`}
                  style={{ transition: 'opacity 1s ease-in-out' }}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
      <div className="relative">
        <div className="absolute bottom-[-1px] left-0 right-0 w-full aspect-[10/1] ">
          <Cutout bgColor="#FFFFFF" points="0,100 0,0 100,70 1000,0 1000,100"/>
        </div>
      </div>
      <div>
        <div className="relative w-full overflow-hidden h-24">
          <div className="absolute flex left-1/2 lifecycle-animation" ref={iconsContainerRef}>
            {renderIcons()}
            {renderIcons()}
            {renderIcons()}
            {renderIcons()}
            {renderIcons()}
          </div>
        </div>
        <div className="h-12 flex justify-center text-center">
          <div className="relative w-full h-full">
            {labelsArray.map((src, index) => (
              <p
                key={src}
                alt={`Lifecycle ${index}`}
                className={`absolute w-full text-lg md:text-xl lg:text-2xl h-auto opacity-0 top-0 left-0 ${index === currentIndex ? 'active' : ''}`}
                style={{ transition: 'opacity 1s ease-in-out' }}
              >{src}</p>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Section1;
