class WebSocketService {
    constructor() {
        this.socket = null;
        this.messageHandlers = [];
    }
  
    connect(url) {
        this.socket = new WebSocket(url);

        this.socket.onopen = () => {
            console.log('WebSocket Connected');
        };

        this.socket.onmessage = (event) => {
            const messages = event.data.split('\n').filter(item => item);
            for (const message of messages) {
                console.log(message)
                const data = JSON.parse(message);
                console.log('New status from server ', data);
                // Notify all registered handlers
                this.messageHandlers.forEach(handler => handler(data));
            }
        };

        this.socket.onclose = (event) => {
            console.log('WebSocket Disconnected', event.reason);
        };

        this.socket.onerror = (error) => {
            console.error('WebSocket Error ', error);
        };
    }
  
    // Register a handler for incoming WebSocket messages
    registerMessageHandler(handler) {
        this.messageHandlers.push(handler);
    }
  
    // Unregister a handler
    unregisterMessageHandler(handler) {
        this.messageHandlers = this.messageHandlers.filter(h => h !== handler);
    }
  
    sendMessage(message) {
        if (this.socket.readyState === WebSocket.OPEN) {
            this.socket.send(JSON.stringify(message));
        } else {
            console.error('WebSocket is not connected.');
        }
    }
}

const webSocketService = new WebSocketService();
export default webSocketService;
  