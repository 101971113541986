const generateClipPathId = () => `clipPath-${Date.now()}-${Math.random() * 1000}`;

const Cutout = ({ bgColor, points }) => {
    const clipPathId = generateClipPathId();

    return (
        <svg width="100%" height="100%" viewBox="0 0 1000 100" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <clipPath id={clipPathId}>
                    <polygon points={points} />
                </clipPath>
            </defs>

            <rect width="100%" height="100%" clipPath={`url(#${clipPathId})`} fill={bgColor} />
        </svg>
    )
}

export default Cutout;