import React, { useState, useEffect } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    BarElement,
    Tooltip,
    Legend,
  } from 'chart.js';
import { Pie, Bar } from 'react-chartjs-2';
import getProductEmission from './../components/betatool/get_data.js'
import { getPieImpactData, pieImpactOptions } from '../components/betatool/pie_impact.js'
import { getBarMassShareData, barMassShareOptions } from '../components/betatool/bar_mass_share.js'
import IngredientTable from './../components/betatool/ingredient_table.js'
import Card from '../components/betatool/product_card.js'
import Product1 from './../images/betatool/philadelphia.jpg'
import Product2 from './../images/betatool/chocolate.jpg'
import Product3 from './../images/betatool/tyrrells-chips.jpg'
import Logo from './../images/webapp/logo.js'
import webSocketService from '../components/betatool/websocket.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    ArcElement,
    BarElement,
    Tooltip,
    Legend
  );

function BetaTool() {
    const [barcode, setBarcode] = useState("");
    const [wsId, setWsId] = useState("");
    const [data, setData] = useState(null);
    const [err, setErr] = useState(null);
    const [dataSize, setDataSize] = useState(null);
    const [loading, setLoading] = useState(null);
    const [loadingStatus, setLoadingStatus] = useState("Starting algorithm")

    const startCalculation = async () => {
        setErr(null)
        setLoading(true);
        try {
            await getProductEmission(barcode, wsId);
        }
        catch {
            setErr('Something went wrong. Please use another barcode or try again later')
            setLoading(null);
            setLoadingStatus("Starting algorithm")
        }
    }

    useEffect(() => {
        const handleMessage = (data) => {
            if('wsId' in data) {
                setWsId(data.wsId);
            }
            if('message' in data)
                console.log(data.log);
            if('error' in data) {
                if(!data.error.includes('FutureWarning: Calling float on a single element')) {
                    console.error(data.error);
                    setErr('Something went wrong. Please use another barcode or try again later')
                    setLoading(null);
                    setLoadingStatus("Starting algorithm")
                }
            }
            if('status' in data) {
                const status = data.status;
                setLoadingStatus(status);
            }
            if('mass_share' in data) {
                setData(data);
                setDataSize(Object.keys(data.mass_share).length)
                setLoading(null);
                setLoadingStatus("Starting algorithm")
            }
        };

        const websocketURL = process.env.REACT_APP_WEBSOCKET_URL;
        webSocketService.connect(websocketURL);
        webSocketService.registerMessageHandler(handleMessage);

        // Clean up by closing connection and unregistering the handler
        return () => {
            webSocketService.unregisterMessageHandler(handleMessage);
            webSocketService.socket.close();
        };
    }, []);

    return (
        <div>
            <div className="p-6 text-4xl flex-col text-center font-medium">
                <p>Explore</p>
                <p>Sproutfull's capabilities</p>
            </div>
            <div className="p-4 flex-col text-center">
                <p>Enter a barcode and get your impact score right now!</p>
            </div>
            <div className="w-full flex items-center justify-center gap-x-10 gap-y-4 pb-6 flex-wrap">
                <input className="rounded-md"
                    type="text"
                    placeholder="Enter barcode here"
                    value={barcode}
                    onChange={(e) => setBarcode(e.target.value)}
                />
                <button id="get" type="button" className="px-[10px] py-[10px] bg-light-green rounded-md flex items-center justify-center" onClick={startCalculation}>
                    <p className="mx-[10px]">Calculate impacts</p>
                </button>
            </div>
            {!data && !loading && <div>
                {err && <div>
                    <p id="errorStatus" className="text-center text-red-500 p-4">{err}</p>
                </div>}
                <div className="p-4 flex-col text-center">
                    <p>Or try one of these products:</p>
                </div>
                <div className="flex justify-center items-center space-x-10 pb-10">
                    <Card ImgSrc={Product1} Title="Philadelphia" setBarcode={setBarcode} Barcode="7622201693916"/>
                    <Card ImgSrc={Product2} Title="Lindt 70% cacao" setBarcode={setBarcode} Barcode="3046920022651"/>
                    <Card ImgSrc={Product3} Title="Tyrrell's crisps" setBarcode={setBarcode} Barcode="5060042641000"/>
                </div> 
            </div>}
            {loading && <div className="p-20">
                <div className="flex justify-center items-center">
                    <Logo className="w-[calc(100px+3vw)]" animate={true} loop={true}/>
                </div>
                <p id="loadingStatus" className="text-center p-4">{loadingStatus}</p>
            </div>}
            <div className="w-full pb-10 flex justify-center">
                {data && <div className="w-[100%] mx-[10vw] border-8 rounded-2xl flex justify-center">
                    <div className="w-full md:w-[70%] lg:w-[50%] xl:w-[70%] h-full mx-[50px] my-[50px] flex-col justify-center text-center">
                        <p className="text-3xl">{data.product_name}</p>
                        <div className="h-4"/>
                        <p className="text-2xl pb-2">Impact per step</p>
                        <div className="flex justify-center items-center">
                            <div className="w-full md:w-[90%] xl:w-[50%] aspect-square">
                                <Pie data={getPieImpactData(data.impact_per_step)} options={pieImpactOptions}/>
                            </div>
                        </div>
                        <div className="h-16"/>
                        <p className="text-2xl pb-2">Mass share per ingredient</p>
                        <div className="w-full flex justify-center">
                            <div className="w-full xl:w-[70%]" style={{ height: `${dataSize * 50}px` }}>
                                <Bar data={getBarMassShareData(data.mass_share)} options={barMassShareOptions}/>
                            </div>
                        </div>
                        <div className="h-16"/>
                        <p className="text-2xl pb-2">Impacts</p>
                        <IngredientTable data={data.impact_data} key={`datatable-${data.product_name}`} />
                    </div> 
                </div> }
            </div>
        </div>
    )
}

export default BetaTool;