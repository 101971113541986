import logo from './../images/footer/sproutfull_logo_white.png';

function NavBar() {
    return (
        <footer className="bg-dark-green text-gray-400 py-8 px-4">
            <div className="grid md:grid-cols-5 gap-4">
                <div className="md:col-start-3 pl-8 pt-12 lg:pt-4">
                    <h2 className="text-lg font-semibold mb-4">Site Links</h2>
                    <ul className="text-sm">
                    <li className="">
                        <a className=" text-light-green" href="https://app.sproutfull.nl/">Launch app</a>
                    </li>
                    <li className="">
                        <a href="/">Home</a>
                    </li>
                    <li className="">
                        <a href="/about/">About</a>
                    </li>
                    {/* <li className="">
                        <a href="/contact/">Contact</a>
                    </li> */}
                    </ul>
                </div>
                <div className="md:col-start-4 md:col-span-2 pl-8 md:pl-0 pt-12 lg:pt-4">
                    <h2 className="text-lg font-semibold mb-4">Contact Us</h2>
                    <p className="text-sm">+31 6 83710674</p>
                    <p className="text-sm">jonah-jan.willemsen@sproutfull.nl</p>
                    <p className="text-sm pt-2">Bronland 10</p>
                    <p className="text-sm">6708WH, Wageningen</p>
                    <p className="text-sm pt-2">KVK: 82149194</p>
                </div>
                <div className="col-span-full md:row-start-1 md:col-start-1 md:col-span-2 flex items-center">
                    <img src={logo} alt="Slogan" className="aspect-[281/100] scale-50 md:scale-75 lg:scale-50"/>
                </div>
            </div>
        </footer>
    )
}

export default NavBar;