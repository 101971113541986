async function getProductEmission(barcode, wsId) {
    try {
        const requestOptions = {
            method: 'GET',
            headers: { 'barcode': barcode, 'wsid': wsId }
        };
        
        const fetchresult = await fetch("/api/getProductEmission", requestOptions);
        if (!fetchresult.ok) {
            // If the HTTP status code is not successful (not 200-299)
            throw new Error(`HTTP error! Status: ${fetchresult.status}`);
        }
        const data = await fetchresult.json();
        return data;
    } catch (error) {
        console.error("Failed to fetch data:", error);
        throw error; // Re-throw the error if needed
    }
}

export default getProductEmission;