import Section1 from "./../components/about/section1";

function About() {
  return (
    <div>
      <Section1/>
    </div>
  )
}

export default About;