

const getBarMassShareData = (data) => {
    const labels = Object.keys(data);
    const values = Object.values(data);
    const color = process.env.REACT_APP_DARK_GREEN;

    const dataset = {
        labels: labels,
        datasets: [
            {
                label: 'Mass share (%)',
                data: values,
                backgroundColor: color
            },
        ],
    };

    return dataset;
}

const barMassShareOptions = {
    indexAxis: 'y',
    responsive: true,
    maintainAspectRatio: false,
    ticks: {
        autoSkip: false,
    },
    plugins: {
        tooltip: {
            callbacks: {
                label: function(context) {
                    const value = context.parsed.x; // Get the original value
                    const formattedValue = value + '%'; // Add the unit
                    return `${formattedValue}`;
                },
            },
            bodyFont: {
                size: 20 // Set the tooltip body font size here
            },
            titleFont: {
                size: 20 // Set the tooltip title font size here, if you have titles
            }
        },
    },
};

export { getBarMassShareData, barMassShareOptions }