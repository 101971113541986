import logo from '../../images/contact/heading.png';
import jonah from '../../images/about/jonah.png';

function Section1() {
  return (
    <div className="font-arial">
      <div className="h-24 w-full flex justify-center items-center pt-1">
        <img src={logo} alt="Logo" className="aspect-[371/100] max-h-[100%]"/>
      </div>
      <div className="pb-10 w-[100%] flex justify-center items-center py-4">
        <div className="w-[80vw] md:w-[70vw] lg:w-[60vw] flex justify-center items-center gap-10">
          <div className="w-[calc(80% - 10vw)] h-[calc(50vh-10vw)] flex flex-col gap-4 justify-center items-center md:items-start md:text-center pl-4 md:pl-0">
            <p className="text-md md:text-lg text-left max-w-lg">We're here to support your sustainability efforts. If you have any questions or need assistance, please don't hesitate to get in touch.</p>
            <div className="h-[20vh] aspect-[100/122] md:hidden">
              <img src={jonah} alt="Jonah-Jan Willemsen" className="h-[100%] w-[100%] rounded-lg"/>
            </div>
            <div className="flex-col">
              <div className="h-[45px] md:h-[50px] w-[100%] flex gap-4 py-2 items-center">
                <img key={`email-icon`} src={`/Icons/phone.svg`} alt={`Email`} className="h-[100%] aspect-square"/>
                <p className="text-md md:text-lg">+31 6 83710674</p>
              </div>
              <div className="h-[45px] md:h-[50px] w-[100%] flex gap-4 py-2 items-center">
                <img key={`email-icon`} src={`/Icons/email.svg`} alt={`Email`} className="h-[100%] aspect-square"/>
                <p className="text-md md:text-lg">jonah-jan.willemsen@sproutfull.nl</p>
              </div>
            </div>
          </div>
          <div className="h-[20vh] hidden md:block aspect-[100/122]">
            <img src={jonah} alt="Jonah-Jan Willemsen" className="h-[100%] w-[100%] rounded-lg"/>
          </div>
        </div>
      </div>
    </div>

  )
}

export default Section1;