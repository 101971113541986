// import Disclaimer from "./../components/home/disclaimer";
import Section1 from "./../components/home/section1";
import Section2 from "./../components/home/section2";


function Home() {
  // const [barcode, setBarcode] = useState("");

  return (
    <div>
      {/* <Disclaimer/> */}
      <Section1/>
      <Section2/>
    </div>
  )
}

export default Home;