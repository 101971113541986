import agriImg from '../../images/home/section1/LC_images/agriculture.png'
import transImg from '../../images/home/section1/LC_images/transport.png'
import facImg from '../../images/home/section1/LC_images/factory.png'
import marketImg from '../../images/home/section1/LC_images/supermarket.png'
import cookImg from '../../images/home/section1/LC_images/cooking.png'

const imagesArray = [
    agriImg,
    transImg,
    facImg,
    marketImg,
    cookImg,
];

const iconsArray = [
    '/LC_images/usage.svg',
    '/LC_images/recycle.svg',
    '/LC_images/agriculture.svg',
    '/LC_images/transport.svg',
    '/LC_images/factory.svg'
];

const labelsArray = [
    'Agriculture',
    'Transport',
    'Factory',
    'Usage',
    'Recycle'
];

export { imagesArray, iconsArray, labelsArray};