const getPieImpactData = (data) => {
    const labels = Object.keys(data);
    const values = Object.values(data);

    const dataset = {
        labels: labels,
        datasets: [
            {
                data: values,
                backgroundColor: ['#2A9DF4', '#FF6B6B', '#32CD32', '#9400D3', '#FFA500', '#008080'],
                hoverBackgroundColor: ['#53B1F4', '#FF8989', '#57D957', '#B23AEE', '#FFB347', '#40E0D0'],
            },
        ],
    };

    return dataset;
}

const pieImpactOptions = {
    animation: {
        animateScale: true,
        animateRotate: true,
    },
    plugins: {
        legend: {
            labels: {
                font: {
                    size: 20 // Set the font size here
                }
            }
        },
        tooltip: {
            // This applies to the tooltips when hovering over the pie sections
            callbacks: {
                label: function(context) {
                    const label = context.dataset.label || ''; // Get the label
                    const value = context.parsed; // Get the original value
                    const formattedValue = value + '%'; // Add the unit
                    return `${label} ${formattedValue}`;
                },
            },
            bodyFont: {
                size: 20 // Set the tooltip body font size here
            },
            titleFont: {
                size: 20 // Set the tooltip title font size here, if you have titles
            }
        }
    }
};

export { getPieImpactData, pieImpactOptions }