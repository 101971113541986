import logo from '../../images/about/heading.png';
import jonah from '../../images/about/jonah.png';
import gideon from '../../images/about/gideon.png';

function Section1() {
  return (
    <div className="font-arial">
      <div className="h-24 w-full flex justify-center items-center pt-1">
        <img src={logo} alt="Logo" className="aspect-[371/100] max-h-[100%]"/>
      </div>
      <div className="flex justify-center items-center py-4">
        <div className="w-[70vw] md:w-[60vw] lg:w-[35vw] grid grid-cols-2 gap-4">
          <div className="md:row-start-1">
            <img src={jonah} alt="Jonah-Jan Willemsen" className=""/>
          </div>
          <div className="flex flex-col justify-center items-start md:items-center md:text-center pl-4 md:pl-0">
            <p className="text-xl font-bold">Jonah-Jan Willemsen</p>
            <p className="text-lg">Business</p>
          </div>
          <div className="bg-slate-400 md:row-start-1">
            <img src={gideon} alt="Gideon Noya" className=""/>
          </div>
          <div className="flex flex-col justify-center items-start md:items-center md:text-center pl-4 md:pl-0">
            <p className="text-xl font-bold">Gideon Noya</p>
            <p className="text-lg">Software</p>
          </div>
        </div>
      </div>
      <div className="py-8 flex flex-col items-center">
        <div className="w-[80%] md:w-[70%] lg:w-[60%]">
          <p>
            Our goal is to enhance the collaborating potential of stakeholders in food supply chains. With the focus on using <strong>transparency as a "means" </strong>
            for an economically feasible sustainability transition.
          </p>
          <br/>
          <p>
            We are developing a sustainability measurement software that allows manufacturers to calculate the sustainability of their products and be transparent about it. These calculations "can" be saved in a database that allows supermarkets to precisely measure the sustainability of their product portfolio.
          </p>
        </div>
      </div>
    </div>

  )
}

export default Section1;