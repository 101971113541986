const Card = ({ ImgSrc, Title, setBarcode, Barcode }) => {
    return (
        <div 
            className="h-48 w-40 rounded-xl overflow-hidden bg-white shadow-[5px_5px_5px_rgba(0,0,0,0.5)] cursor-pointer hover:-translate-y-2 hover:shadow-[13px_13px_5px_rgba(0,0,0,0.3)] duration-300"
            onClick={() => setBarcode(Barcode)}
        >
            <div className="h-[70%] flex justify-center items-center p-4">
                <img
                key={ImgSrc}
                src={ImgSrc}
                alt={Title}
                />
            </div>
            <div className="h-[30%] flex justify-center items-center">
                <p>{Title}</p>
            </div>
        </div>
    )
}

export default Card;