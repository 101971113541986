import measure from '../../images/home/section2/measurements_logo.png'
import actionable from '../../images/home/section2/actionable_logo.png'
import simple from '../../images/home/section2/simplified_logo.png'
import efficient from '../../images/home/section2/cost_efficient_logo.png'
import Logo from '../../images/webapp/logo.js'
import Cutout from '../cutout.js'
import React, { useState, useEffect } from 'react';

function Section2() {
    const [animate, setAnimate] = useState(false);
    const bgColor = process.env.REACT_APP_DARK_GREEN;

    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 100) setAnimate(true);
        };
    
        window.addEventListener('scroll', handleScroll);
    
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="h-[calc(600px+30vw)] md:h-[calc(400px+30vw)]">
            <div className="relative w-full h-[calc(80px+3vw)] md:mt-4 lg:mt-8 flex justify-center ">
                <div className="absolute top-4 aspect-square">
                    <Logo className="w-[calc(80px+3vw)]" animate={animate}/>
                </div>
            </div>
            <div className="w-full h-[calc(100%-(80px+3vw))] bg-light-blue flex justify-center">
                <div className="h-[90%] w-[80vw] lg:w-[70vw] xl:w-[60vw] flex flex-col font-arial my-6 bg-light-blue">
                    <div className="h-[20%] md:h-[15%] flex justify-center items-center border-dark-green border-b-2 mb-8">
                        <p className="text-2xl lg:text-3xl xl:text-4xl my-6">Introducing the Sproutfull app!</p>
                    </div>
                    <div className="h-[70%] flex flex-col justify-center md:flex-row md:justify-between">
                        <div className="md:w-[50%] flex justify-center text-center md:text-end items-center mb-4">
                            <p className="max-w-[calc(100px+20vw)] text-lg md:text-xl lg:text-2xl xl:text-3xl">Our platform simplifies environmental impact analysis, saving you costs without compromising insight</p>
                        </div>
                        <div className="md:w-[50%] flex justify-center items-center">
                            <div className="md:p-0 flex-col">
                                <div className="flex justify-start items-center pt-6 pb-2">
                                    <img src={simple} alt="AI" className="w-[12vw] md:w-[6vw] aspect-[166/100]"></img>
                                    <p className="text-lg md:text-[calc(10px+0.8vw)] pl-2">Simplified Analysis</p>
                                </div>
                                <div className="flex justify-start items-center pb-2">
                                    <img src={measure} alt="Precise" className="w-[12vw] md:w-[6vw] aspect-[166/100]"></img>
                                    <p className="text-lg md:text-[calc(10px+0.8vw)] pl-2">Tailored for You</p>
                                </div>
                                <div className="flex justify-start items-center pb-2">
                                    <img src={actionable} alt="Free" className="w-[12vw] md:w-[6vw] aspect-[166/100]"></img>
                                    <p className="text-lg md:text-[calc(10px+0.8vw)] pl-2">Actionable Data</p>
                                </div>
                                <div className="flex justify-start items-center pb-6">
                                    <img src={efficient} alt="Assistant" className="w-[12vw] md:w-[6vw] aspect-[166/100]"></img>
                                    <p className="text-lg md:text-[calc(10px+0.8vw)] pl-2">Cost Efficient</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="relative">
                <div className="absolute bottom-[-1px] left-0 right-0 w-full aspect-[10/1] ">
                    <Cutout bgColor={bgColor} points="0,100 0,0 800,70 1000,0 1000,100"/>
                </div>
            </div>
        </div>
    )
}

export default Section2;